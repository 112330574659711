<template>
  <div class="cooperation">
    <div class="center">
      <p class="title">为什么选择我们？</p>
      <div class="item">
          <img src="@/assets/img/1.png" alt="">
          <div class="rightBox">
            <p class="itemTitle">深耕教育行业</p>
            <p class="itemCenter">深耕教育行业12年<br>专注行业信息化的建设与发展<br>了解行业深层次的痛点与需求</p>
          </div>
      </div>
      <div class="item item2">
        <img src="@/assets/img/2.png" alt="">
        <div class="rightBox">
          <p class="itemTitle">完善服务支撑</p>
          <p class="itemCenter">拥有高效敏捷的服务支撑的团队<br>可及时为代理商提供<br>有效的产品问题解决方案和技术支撑。</p>
        </div>
      </div>
      <div class="item item3">
        <img src="@/assets/img/3.png" alt="">
        <div class="rightBox">
          <p class="itemTitle">技术实力雄厚</p>
          <p class="itemCenter">研发团队来自国内各先进互联网公司<br>团队时刻紧跟技术发展<br>不断用先进的技术来升级产品</p>
        </div>
      </div>
      <div class="item item2">
        <img style="width: 600px;height:560px;" src="@/assets/img/4.png" alt="">
        <div class="rightBox">
          <p class="itemTitle">品牌诚信保障</p>
          <p class="itemCenter">诚信经营品牌保障，持续高收益<br>技术升级产品，实力雄厚</p>
        </div>
      </div>
    </div>
    <div class="jiameng">
      <div class="jiamengCenter">
        <div class="jmLeft">
          <p>加盟需求</p>
          <p>1、拥有现成的客户资源，在签约区域内至少已服务30家视频幼儿园。 <br>
            2、专职销售团队，每个区域内至少拥有3人以上专职人员。 <br>
            3、具有合法营业执照和独立法人代表，有良好的商业信誉和口碑。<br>
            4、认同金树丫的产品价值和文化理念，愿意长期合作。</p>
        </div>
        <div class="jmRight">
          <input type="text" v-model="dataForm.name" style="margin: 50px 0 20px 0" placeholder="请输入姓名">
          <input type="text" v-model="dataForm.mobile" placeholder="请输入联系电话">
          <div class="ljjm" @click="submit">立即加盟</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data(){
    return{
      dataForm: {
        mobile: '',
        name: ''
      }
    }
  },
  methods:{
    submit(){
      if(this.dataForm.name === ''){
        this.$message.warning('姓名不能为空');
        return;
      }
      if(!(/^((13[0-9])|(14[0-9])|(15([0-9]))|(16([0-9]))|(17([0-9]))|(18[0-9]))|(19([0-9]))\d{8}$/.test(this.dataForm.mobile))){
        this.$message.warning('请填写正确的11位手机号码');
        return;
      }
      axios.post('http://gateway.yjbit.zhidiansaas.com:30191/schools/unAccess/door/agentRegister',this.dataForm).then(res=>{
        console.log(res)
        if(res.data.code === 0) {
          this.$message.success('提交成功');
          this.dataForm.name = ''
          this.dataForm.mobile = ''
        } else {
          this.$message.warning(res.data.msg);
        }
      })
    }
  }
}
</script>
<style lang="less">
.cooperation{
  .center{
    width: 1200px;
    margin:auto;
    .title{
      text-align: center;
      //font-weight:bold;
      font-size: 68px;
      padding: 117px 0;
      margin:0;
    }
    .item{
      overflow: hidden;
      width: 100%;
      img{
        border: 1px dashed #ccc;
        width: 622px;
        height: 455px;
        float:left;
      }
      .rightBox{
        float:right;
        .itemTitle{
          padding-top: 84px;
          text-align:right;
          margin: 0;
          font-size: 56px;
          color:#1e1e1e;
        }
        .itemCenter{
          color:#6E6E6E;
          font-size:26px;
          text-align:right;
        }
      }
    }
    .item2{
      margin-top: 142px;
      img{
        float:right;
        width: 535px;
        height: 552px;
      }
      .rightBox{
        float: left;
      }
      .itemTitle{
        padding-left: 170px;
        text-align: left !important;
      }
      .itemCenter{
        padding-left: 170px;
        text-align:left !important;
      }
    }
    .item3{
      margin-top: 113px;
      .itemTitle{
        padding-top: 102px;
      }
      img{
        width: 500px;
        height: 488px;
      }
    }
  }
  .jiameng{
    margin-top: 204px;
    width: 100%;
    height: 414px;
    background: #E9E9E9;
    .jiamengCenter{
      width: 1200px;
      margin: auto;
      .jmLeft{
        width:650px;
        float:left;
        p:nth-child(1){
          margin: 27px 0;
          font-size: 56px;
          color:#1e1e1e;
        }
        p:nth-child(2){
          font-size: 20px;
          color: #6E6E6E;
          line-height: 38px;
        }
      }
      .jmRight{
        width: 550px;
        float: right;
        input{
          outline: none;
          font-size: 20px;
          padding-left: 35px;
          width: 397px;
          height: 56px;
          background: #FAFAFB;
          border: 1px solid #B4B4B4;
          opacity: 1;
          border-radius: 28px;
        }
        .ljjm{
          cursor: pointer;
          margin-top: 35px;
          margin-left: 50px;
          width: 328px;
          height: 56px;
          background: #FBD646;
          opacity: 1;
          border-radius: 28px;
          text-align:center;
          line-height: 56px;
        }
      }
    }
  }
}
</style>
